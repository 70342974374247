import React, { ReactNode } from 'react'
import NewLaunchCard from '../../../cards/NewLaunchCard'
import LegacyCarousel from '../../../LegacyCarousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { IState } from '../../Index'
import { NewListing } from '../../../../pages'
import { PropertyType } from '../../../../utils/Types'
import { useInView } from 'react-intersection-observer'
import NewCarousel from '../../../NewCarousel'

type Props = {
    data: NewListing[]
    lang: any
    state: IState
    isMobile: boolean
    breakpoint: string
}

const NewLaunch = (props: Props) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    })

    const renderNewLaunch = () => {
        let output: ReactNode[] = []

        if (props.data.length > 0) {
            output = props.data.map((data, idx) =>
                <div key={idx} className="w-full md:w-1/4 flex-shrink-0 space-y-4 > * mb-4 lg:mb-0">
                    <NewLaunchCard
                        id={data.id as string}
                        property_type={(data.property_type! as PropertyType)}
                        photos={data.photos}
                        is_wishlisted={data.is_wishlisted}
                        // is_dollar={!!data.is_dollar}
                        // is_sold={!!data.is_sold}
                        is_sell={props.state.c === '1'}
                        title={data.name}
                        electric_watt={data.electric_watt}
                        bedroom={data.bedroom}
                        bathroom={data.bathroom}
                        price={data.price.toString()}
                        property_area={data.property_area ? Number(data.property_area) : undefined}
                        land_area={data.land_area ? Number(data.land_area) : undefined}
                        location={data.location!}
                        direction={data.direction}
                        floor={data.floor}
                        furnish={data.furnish as "full" | "semi" | "non" | undefined}
                        view={data.view}
                        priceDuration="none"
                        url={data.url!.replace('bsd-residential', 'bsd-city').replace('bsd-commercial', 'bsd-city')}
                        lang={props.lang}
                        containerClasses="p-0 md:mx-1 border border-gray-e6"
                        contentPadding="px-3 md:px-3.5 pt-1 pb-3"
                        sold_out={data.sold_out}
                        cluster_label={data.cluster_label}
                        cluster_category={data.cluster_category}
                        has_360={data.has_360}
                    />
                </div>
            )
        }

        return output
    }

    const disabledLeftArrow = () => (
        <div className="hidden lg:block absolute  right-43 mr-4 z-1" style={{ top: -(20 + 47) }}>
            <button className="text-white bg-gray-cf w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                <FontAwesomeIcon icon={faAngleLeft} width="0" className="text-white" size="lg" />
            </button>
        </div>
    )

    const disabledRightArrow = () => (
        <div className="hidden lg:block absolute  right-0 z-1" style={{ top: -(20 + 47) }}>
            <button className="text-white bg-gray-cf w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                <FontAwesomeIcon icon={faAngleRight} width="0" className="text-white" size="lg" />
            </button>
        </div>
    )

    return (
        <div className={`relative container flex flex-col mb-12`}>
            <div ref={ref} className='flex items-center mb-5'>
                <h2 className='text-black-#3D3D3D text-lg lg:text-32px font-bold'>New Launch</h2>
                <div className='py-1 px-3 bg-main rounded-full ml-3 text-white text-xs lg:text-base '>
                    New !
                </div>
            </div>

            {
                inView &&
                <div className='md:flex relative'>
                    <div className='w-full'>
                        <NewCarousel
                            alwaysShowArrow
                            disabledLeftArrow={disabledLeftArrow}
                            disabledRightArrow={disabledRightArrow}
                            show={props.isMobile ? 1 : 4}
                            isRepeatedProps={['xs', 'sm', 'md'].includes(props.breakpoint)}
                            withIndicator
                            indicatorContainerClasses={'md:mt-5'}
                            indicatorShow={1}
                            leftArrow={prev =>
                                <div className="hidden lg:block absolute  right-43 mr-4 z-1" style={{ top: -(20 + 47) }}>
                                    <button onClick={prev} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                        <FontAwesomeIcon icon={faAngleLeft} width="0" className="text-white" size="lg" />
                                    </button>
                                </div>
                            }
                            rightArrow={next =>
                                <div className="hidden lg:block absolute  right-0 z-1" style={{ top: -(20 + 47) }}>
                                    <button onClick={next} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                        <FontAwesomeIcon icon={faAngleRight} width="0" className="text-white" size="lg" />
                                    </button>
                                </div>
                            }
                        >
                            {renderNewLaunch()}
                        </NewCarousel>
                    </div>
                </div>
            }
        </div>
    )
}

export default React.memo(NewLaunch) 